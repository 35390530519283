import ChatIcon from "./component/ChatIcon";
import ContactUsForm from "./component/ContactUsForm";
import OurData from "./component/OurData";

function App() {
  return (
    <>
    <ChatIcon />
    <nav className="navbar navbar-light navbar-expand sticky-top bg-light navigation-clean">
        <div className="container">
          <a className="navbar-brand" href="#">
            Brand
          </a>
        <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1"></button>
            <div className="collapse d-none justify-content-xl-end d-md-flex" id="navcol-1">
                <ul className="navbar-nav">
                    <li className="nav-item"><a className="nav-link" href="#intro">Home</a></li>
                    <li className="nav-item"><a className="nav-link" href="#showcase">Products</a></li>
                    <li className="nav-item"><a className="nav-link" href="#review">About Us</a></li>
                    <li className="nav-item"><a className="nav-link" href="#our-data">Our Data</a></li>
                    <li className="nav-item"><a className="nav-link" href="#contact-us">Contact Us</a></li>
                </ul>
            </div>
        </div>
    </nav>
    {/* <header className="text-center text-white masthead custom-header">
        <div className="overlay"></div>
    </header> */}
    <img className="custom-header-agent" src="assets/img/agent-img.png" alt="agent" />
    <img className="custom-header" src="assets/img/property-background.jpg" alt="background" />
    <section id="intro" className="text-center bg-light features-icons">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
                        <div className="d-flex features-icons-icon"><i className="icon-screen-desktop m-auto text-primary" data-bss-hover-animate="pulse"></i></div>
                        <h3>Fully Responsive</h3>
                        <p className="lead mb-0">This theme will look great on any device, no matter the size!</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
                        <div className="d-flex features-icons-icon"><i className="icon-layers m-auto text-primary" data-bss-hover-animate="pulse"></i></div>
                        <h3>Bootstrap 5 Ready</h3>
                        <p className="lead mb-0">Featuring the latest build of the new Bootstrap 5 framework!</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3">
                        <div className="d-flex features-icons-icon"><i className="icon-check m-auto text-primary" data-bss-hover-animate="pulse"></i></div>
                        <h3>Easy to Use</h3>
                        <p className="lead mb-0">Ready to use with your own content, or customize the source files!</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="showcase" className="showcase">
        <div className="container-fluid p-0">
            <div className="row g-0">
                <div className="col-lg-6 text-white order-lg-2 showcase-img showcase1" ><span></span></div>
                <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                    <h2>Fully Responsive Design</h2>
                    <p className="lead mb-0">When you use a theme created with Bootstrap, you know that the theme will look great on any device, whether it's a phone, tablet, or desktop the page will behave responsively!</p>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-lg-6 text-white showcase-img showcase2"><span></span></div>
                <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                    <h2>Updated For Bootstrap 5</h2>
                    <p className="lead mb-0">Newly improved, and full of great utility classes, Bootstrap 5 is leading the way in mobile responsive web development! All of the themes are now using Bootstrap 5!</p>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-lg-6 text-white order-lg-2 showcase-img showcase3"><span></span></div>
                <div className="col-lg-6 my-auto order-lg-1 showcase-text">
                    <h2>Easy to Use &amp;&nbsp;Customize</h2>
                    <p className="lead mb-0">Landing Page is just HTML and CSS with a splash of SCSS for users who demand some deeper customization options. Out of the box, just add your content and images, and your new landing page will be ready to go!</p>
                </div>
            </div>
        </div>
    </section>
    <section id="review" className="text-center bg-light testimonials">
        <div className="container">
            <h2 className="mb-5">What people are saying...</h2>
            <div className="row">
                <div className="col-lg-4">
                    <div className="mx-auto testimonial-item mb-5 mb-lg-0"><img className="rounded-circle img-fluid mb-3" src="assets/img/testimonials-1.jpg" />
                        <h5>Margaret E.</h5>
                        <p className="font-weight-light mb-0">"This is fantastic! Thanks so much guys!"</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="mx-auto testimonial-item mb-5 mb-lg-0"><img className="rounded-circle img-fluid mb-3" src="assets/img/testimonials-2.jpg" />
                        <h5>Fred S.</h5>
                        <p className="font-weight-light mb-0">"Bootstrap is amazing. I've been using it to create lots of super nice landing pages."</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="mx-auto testimonial-item mb-5 mb-lg-0"><img className="rounded-circle img-fluid mb-3" src="assets/img/testimonials-3.jpg" />
                        <h5>Sarah W.</h5>
                        <p className="font-weight-light mb-0">"Thanks so much for making these free resources available to us!"</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section id="our-data">
        <OurData />
    </section>
    <section id="contact-us" className="mt-5 mb-5">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.4454622822514!2d103.76087987496604!3d1.5030946984828089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da6df697838b71%3A0xe01d2de6fa8627d3!2sParagon%20Gateway!5e0!3m2!1sen!2smy!4v1695390125094!5m2!1sen!2smy" 
                    width="100%" height="450" style={{"border":0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                    </iframe>                
                </div>
                <div className="col-md-6">
                    <ContactUsForm />
                </div>
            </div>
        </div>
    </section>
    <footer className="bg-light footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 text-center text-lg-start my-auto h-100">
                    <ul className="list-inline mb-2">
                        <li className="list-inline-item"><a href="#">About</a></li>
                        <li className="list-inline-item"><span>⋅</span></li>
                        <li className="list-inline-item"><a href="#contact-us">Contact</a></li>
                        <li className="list-inline-item"><span>⋅</span></li>
                        <li className="list-inline-item"><a href="#">Terms of &nbsp;Use</a></li>
                        <li className="list-inline-item"><span>⋅</span></li>
                        <li className="list-inline-item"><a href="#">Privacy Policy</a></li>
                    </ul>
                    <p className="text-muted small mb-4 mb-lg-0">© Brand 2023. All Rights Reserved - Powered by 
                    <a className="ms-1 powered-by" target="_blank" href="https://www.netzachtechsolution.com">Netzach</a>
                    </p>
                </div>
                <div className="col-lg-6 text-center text-lg-end my-auto h-100">
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"><a href="#"><i className="fa fa-facebook fa-2x fa-fw"></i></a></li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-twitter fa-2x fa-fw"></i></a></li>
                        <li className="list-inline-item"><a href="#"><i className="fa fa-instagram fa-2x fa-fw"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    </>
  );
}

export default App;
