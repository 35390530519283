import React from "react";

function ContactUsForm() {
    const contactNumber = process.env.REACT_APP_PHONE_NUMBER; 
    const nameRef = React.useRef(null);
    const messageRef = React.useRef(null);

    const redirectToWhatsapp = () => {
        let whatsappMessage = 'Hi, i am interested in your business'
        if(messageRef.current.value) {
            whatsappMessage = `${nameRef.current.value ? "Hi, i am " + nameRef.current.value + ", " : ""}${messageRef.current.value}`;
        }
        const whatsappLink = `https://wa.me/${contactNumber}?text=${whatsappMessage}`;
        window.location.href = whatsappLink;
    }
    return (
        <section class="contact-us">
            <div class="row">
                <div class="col text-center mx-auto">
                    <h2 class="display-6 fw-bold mb-4">
                        <span>Interested</span>?
                    </h2>
                    <p class="text-muted">
                        Our team is always here to help. Send us a message and we'll get back to you shortly.
                    </p>
                </div>
            </div>
            <div class="row d-flex justify-content-center">
                <div>
                    <form class="p-3 p-xl-4" method="post">
                        <div class="mb-3">
                            <input class="shadow form-control" type="text" id="name-1" name="name" placeholder="Name" ref={nameRef} />
                        </div>
                        {/* <div class="mb-3">
                            <input class="shadow form-control" type="email" id="email-1" name="email" placeholder="Email" />
                        </div> */}
                        <div class="mb-3">
                            <textarea class="shadow form-control" id="message-1" name="message" rows="6" placeholder="Message" ref={messageRef}></textarea>
                        </div>
                        <div>
                            <button class="btn btn-primary shadow d-block w-100" type="button" onClick={redirectToWhatsapp}>
                                Send Via WhatsApp 
                                <img
                                src="assets/img/WhatsApp.svg.webp"
                                alt="WhatsApp Icon"
                                className="whatsappIcon"
                                />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}
export default ContactUsForm;